import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class CarrierAwbService {

  configService = null;

  constructor(private http: HttpClient) {
    this.configService = new ConfigService();
  }

  createCarrierAWB(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/createCarriersAWB'), data, { headers });
  }

  updateCarrierAWB(data) {
    console.log(data);
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/updateCarriersAWB'), data, { headers });
  }

  getCarriersAWB(data) {
    let params: HttpParams;
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    if (data !== '') {
      if (data === 'onlyShippers') {
        params = new HttpParams()
          .set('onlyShippers', 'true');
      } else if (data === 'onlyConsignees') {
        params = new HttpParams()
          .set('onlyConsignees', 'true');
      } else if (data === 'onlyCarriers'){
        params = new HttpParams()
          .set('onlyCarriers', 'true');
      }else if (data === 'onlyAirlines'){
        params = new HttpParams()
          .set('onlyAirlines', 'true');
      }
    }
  
    return this.http.get(this.configService.serverIp.concat('/getAllCarriersAWB'), { headers, params });
  }

getCarrierAWB(id) {
  const token = localStorage.getItem('currentUser');
  const headers = new HttpHeaders({
    'authorization': token
  });
  const params = new HttpParams()
    .set('id', id);
  return this.http.get(this.configService.serverIp.concat('/getOneCarrierAWB'), { headers, params });
}
}
