import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AwbService {
 
  configService: ConfigService;
  constructor(
    private http: HttpClient) {
    this.configService = new ConfigService();
  }
  createAwb(awb) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = awb;
    return this.http.post(this.configService.serverIp.concat('/createMasterGuide'), data, { headers });
  }

  generateIndirectAirCarrierCertificate(awb) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      'awb': awb
    };
    return this.http.post(this.configService.serverIp.concat('/generateIndirectAirCarrierCertificate'), data, { headers });
  }

  generateDeliveryOrder(requirements) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = requirements;
    return this.http.post(this.configService.serverIp.concat('/generateDeliveryOrder'), data, { headers });
  }
 
  getAllMasterGuide(skip){
      const token = localStorage.getItem('currentUser');
      const headers = new HttpHeaders({
        'authorization': token
      });
      const params = new HttpParams().set('skip', skip);
      return this.http.get(this.configService.serverIp.concat('/getAllMasterGuidesPaged'), { headers, params });
     }

}
