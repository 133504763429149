import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ListUsersService {

  configService: ConfigService;

  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  getLockerUsers(): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/listLockerUsers'), { headers });
  }

  /**
   * Servicio de peticion busqueda por numero de casillero
   * @param value
   * @returns
   */
  getUserNumberLocker(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/globalSearchNumberLocker'), value
    , { headers });
  }

  /**
   * Servicio de peticion busqueda por telefono
   * @param value
   * @returns
   */
  getUserPhoneGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/globalSearchPhone'), value
    , { headers });
  }

  /**
   *  Servicio de peticion busqueda por email
   * @param value
   * @returns
   */
  getUserEmailGlobal(value) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/globalSearchEmail'), value
    , { headers });
  }

    /**
   * Servicio de peticion busqueda por nombre
   * @param value
   */
     getUserNameGlobal(value) {
      const token = localStorage.getItem('currentUser');
      const headers = new HttpHeaders({
        'authorization': token
      });
      return this.http.post(this.configService.serverIp.concat('/globalSearchName'), value
      , { headers });
    }
  /*
    getUser(userId: string): Observable<any> {//<-- No se usa
      const token = localStorage.getItem('currentUser');
      const headers = new HttpHeaders({
        'authorization': token
      });
      const params = new HttpParams().set('id_user', userId);
      return this.http.get(this.configService.serverIp.concat('/getUser'), {headers, params});
    }*/

  /*editUser(user): Observable<any> {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {
      id_user: user.shop_id,
      shopname: user.shopName
    };
    return this.http.post(this.configService.serverIp.concat('/editUser'), data, {headers});
  }*/

  /*deleteUser(user): Observable<any> {//<-- No se usa
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/deleteUser'), user, {headers});
  }*/

  getCostsByUser(userId) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('user_id', userId);
    return this.http.get(this.configService.serverIp.concat('/costsByUser'), { headers, params });
  }

  editCostsByUser(costs) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/editCostByUser'), costs, { headers });
  }

  getServicesByUser(userId) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('user_id', userId);
    return this.http.get(this.configService.serverIp.concat('/userServices'), { headers, params });
  }

  editServiceByUser(service) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/editCostServiceByUser'), service, { headers });
  }

  //
  assignServiceToUser(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/assignServiceToUser'), data, { headers });
  }

}
