import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
@Injectable({
  providedIn: "root",
})
export class MessagesService {
  configService: ConfigService;
  constructor(public http: HttpClient) {
    this.configService = new ConfigService();
  }

  sendMessageToRol(data) {
    const token = localStorage.getItem("currentUser");
    const headers = new HttpHeaders({
      authorization: token,
    });
    return this.http.post(
      this.configService.serverIp.concat("/createMessageToRole"),
      data,
      { headers }
    );
  }

  sendMessageToUser(data) {
    const token = localStorage.getItem("currentUser");
    const headers = new HttpHeaders({
      authorization: token,
    });
    return this.http.post(
      this.configService.serverIp.concat("/createMessageToUser"),
      data,
      { headers }
    );
  }

  getMessage() {
    const token = localStorage.getItem("currentUser");
    const headers = new HttpHeaders({
      authorization: token,
    });
    return this.http.get(
      this.configService.serverIp.concat("/getMessagesToUser"),
      { headers }
    );
  }

  readMessage(data) {
    const token = localStorage.getItem("currentUser");
    const headers = new HttpHeaders({
      authorization: token,
    });
    return this.http.post(
      this.configService.serverIp.concat("/markMessageAsRead"),
      data,
      { headers }
    );
  }
  searchOperator(data) {
    const token = localStorage.getItem("currentUser");
    const headers = new HttpHeaders({
      authorization: token,
    });
    return this.http.post(
      this.configService.serverIp.concat("/searchOperatorByValue"),
      data,
      { headers }
    );
  }
  searchAgency(data) {
    const token = localStorage.getItem("currentUser");
    const headers = new HttpHeaders({
      authorization: token,
    });
    return this.http.post(
      this.configService.serverIp.concat("/searchAgencyByValue"),
      data,
      { headers }
    );
  }
}
