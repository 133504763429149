import {NgModule} from '@angular/core';
import {MatchHeightModule} from '../../shared/directives/match-height.directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared.module';
import { SearchUserComponent } from './search-user.component';
import { RegisterUserModule } from '../register-user/register-user.module';

@NgModule({
  imports: [
    SharedModule,
    MatchHeightModule,
    NgbModule.forRoot(),
    RegisterUserModule
  ],
  exports: [
    SearchUserComponent
  ],
  declarations: [
    SearchUserComponent
  ],
  providers: []
})

export class SearchUserModule {}

