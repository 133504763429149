import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigService} from './config.service';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ConsolidatedService {

  configService: ConfigService;
  constructor(
    private http: HttpClient) {
      this.configService = new ConfigService();
  }
  createConsolidated(dataReciv) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/createConsolidated'), dataReciv, {headers});
  }

  updateConsolidated(dataReciv) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/updateConsolidatedInformation'), dataReciv, {headers});
  }

  updateWeight(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/updateWeight'), data, {headers});
  }

  updateDeclaredPrice(data) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.post(this.configService.serverIp.concat('/updateDeclaredPrice'), data, {headers});
  }

  listConsolidated() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    return this.http.get(this.configService.serverIp.concat('/getAllConsolidated'), {headers});
  }

  getGuidesConsolidated(id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('id', id);
    return this.http.get(this.configService.serverIp.concat('/getGuidesOfConsolidated'), {headers, params});
  }

  registerGuideOnConsolidated(guide, id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {guide: guide, id: id};
    return this.http.post(this.configService.serverIp.concat('/addGuideToConsolidated'), data, {headers});
  }

  removeGuideOnConsolidated(guide, id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {guide: guide, id: id};
    return this.http.post(this.configService.serverIp.concat('/removeGuideFromConsolidated'), data, {headers});
  }

  consolidateConsolidated(masterGuide) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {id: masterGuide};
    return this.http.post(this.configService.serverIp.concat('/consolidateConsolidated'), data, {headers});
  }

  consolidateGetInfo(id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('id', id);
    return this.http.get(this.configService.serverIp.concat('/getConsolidatedInfo'), {headers, params});
  }

  openConsolidated(consolidated_id_open) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {consolidated_id: consolidated_id_open};
    return this.http.post(this.configService.serverIp.concat('/openConsolidated'), data, {headers});
  }

  guidesReport(consolidated_id) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {consolidated_id: consolidated_id};
    console.log(data);
    return this.http.post(this.configService.serverIp.concat('/generateReportGuidesAWB'), data, {headers});
  }

  exportExcel(consolidated_id: number) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {consolidated_id: consolidated_id};
    return this.http.post(this.configService.serverIp.concat('/generateReportAWB'), data, {headers});
  }

  manifestReport(currentGuides) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = currentGuides;
    return this.http.post(this.configService.serverIp.concat('/generateReportManifest'), data, {headers});
  }

  flightReport(currentGuides) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = currentGuides;
    return this.http.post(this.configService.serverIp.concat('/generateFlightReport'), data, {headers});
  }

  getShippers() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('onlyShippers', 'true');
    return this.http.get(this.configService.serverIp.concat('/getAllCarriersAWB'), { headers, params });
   }

   getConsignees() {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });

    const params = new HttpParams().set('onlyConsignees', 'true');
    return this.http.get(this.configService.serverIp.concat('/getAllCarriersAWB'), { headers, params });
   }

   consolidatedColsed(skip){

    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('skip', skip);
    return this.http.get(this.configService.serverIp.concat('/consolidatedColsed'), { headers, params });
   }

   guidesInConsolidateClose(consolidatedID) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const params = new HttpParams().set('consolidateID', consolidatedID);
    return this.http.get(this.configService.serverIp.concat('/guidesInConsolidateClose'), { headers, params });
   }

   applyStatusToConsolidated(consolidatedID) {
    const token = localStorage.getItem('currentUser');
    const headers = new HttpHeaders({
      'authorization': token
    });
    const data = {consolidatedID: consolidatedID };
    return this.http.post(this.configService.serverIp.concat('/applyStatusToConsolidated'), data, {headers});
   }
}
