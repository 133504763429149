import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { RegisterPackageService } from '../../services/register-package.service';
import { AlertMessageService } from '../../services/alert-message.service';

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit {

  searchUserForm: FormGroup;
  foundInfoOfUsers: Array<any>;
  selectedUser: any;
  selectedPackage: any;
  @Output() userSelected = new EventEmitter<any>();
  @Input() showAddUser: boolean;

  constructor(private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    private registerPackageService: RegisterPackageService,
    private alertMessageService: AlertMessageService) { }

  ngOnInit() {
    this.initFormSearchUser();
  }

  showModalToAssignToUser(modal) {
    this.modalService.open(modal, { size: 'lg' });
  }

  initFormSearchUser() {
    this.searchUserForm = this.formBuilder.group({
      'value': ["", Validators.compose([])]
    });
  }

  searchUser() {
    let data = this.searchUserForm.value;
    let language = this.languageService.getCurrentLanguage();

    this.registerPackageService.searchUser(data).subscribe(result => {
      this.foundInfoOfUsers = result['users'];
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    });
  }

  selectedUserEvent() {
    this.userSelected.emit(this.selectedUser);
  }

  userCreated(user: any) {
    this.selectedUser = user;
    this.selectedUserEvent();
  }


}
